import React, { ElementType } from 'react';

import clsx from 'clsx';

import { ChildrenProps } from '@/types/common.types';

import styles from '@/components/Form/Select/SelectValues.module.scss';

// =================================================================

type MultiValueProps<E extends ElementType> = ChildrenProps & {
  as?: E;
};

export const MultiValue = <E extends ElementType>(props: MultiValueProps<E>) => {
  const { as: Element = 'li', children } = props;
  return <Element className={styles.multiValue}>{children}</Element>;
};

// =================================================================

type MultiValueContainerProps<E extends ElementType> = ChildrenProps & {
  as?: E;
};

export const MultiValueContainer = <E extends ElementType>(props: MultiValueContainerProps<E>) => {
  const { as: Element = 'ul', children } = props;
  return <Element className={styles.multiValueContainer}>{children}</Element>;
};

// =================================================================

export const SingleValue = (props: ChildrenProps & { isPlaceholder?: boolean }) => {
  const { children, isPlaceholder } = props;
  return (
    <p
      className={clsx(styles.singleValue, {
        [styles.placeholder]: isPlaceholder,
      })}
    >
      {children}
    </p>
  );
};
