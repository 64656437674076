import { type BaseTransitionProps, BaseTransition } from '@/components/Animations/BaseTransition';
import styles from '@/components/Animations/FadeUpTransition.module.scss';

// =================================================================

export const FadeUpTransition = (props: Omit<BaseTransitionProps, 'classNames'>) => {
  const { children, ...rest } = props;

  return (
    <BaseTransition
      classNames={{
        enter: styles.slideEnter,
        enterActive: styles.slideEnterActive,
        exit: styles.slideExit,
        exitActive: styles.slideExitActive,
      }}
      {...rest}
    >
      {children}
    </BaseTransition>
  );
};
