import { memo } from 'react';

import clsx from 'clsx';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from './Modal.module.scss';

export const ModalBody = memo((props: ChildrenProps & ClassNameProps) => {
  const { children, className } = props;

  return <div className={clsx(styles.modalBody, className)}>{children}</div>;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ModalBody.displayName = 'ModalBody';
}
