'use client';

import { forwardRef, useRef, useCallback, useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import clsx from 'clsx';

import type { ChildrenProps } from '@/types/common.types';

import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import { useMergeRefs } from '@/hooks/useMergeRefs';

import { FadeTransition } from '@/components/Animations';
import styles from '@/components/Overlay/Overlay.module.scss';
import { Portal } from '@/components/Portal';

// =================================================================

export interface OverlayProps extends ChildrenProps {
  isOpen: boolean;
  onClose: () => void;
  shouldUseAnimation?: boolean;
  shouldUsePortal?: boolean;
  hasBackdrop?: boolean;
  canEscapeKeyClose?: boolean;
  canOutsideClickClose?: boolean;
  backdropClassName?: string;
}

// =================================================================

export const Overlay = forwardRef<HTMLDivElement, OverlayProps>((props, ref) => {
  const {
    isOpen,
    onClose,
    shouldUseAnimation = true,
    shouldUsePortal = true,
    hasBackdrop = true,
    canEscapeKeyClose = true,
    canOutsideClickClose = true,
    backdropClassName,
    children,
  } = props;

  const overlayRef = useRef<HTMLDivElement>(null);
  const mergedRef = useMergeRefs(overlayRef, ref);

  const onCloseRef = useRef<() => void>();
  if (!onCloseRef.current) {
    onCloseRef.current = onClose;
  }

  const handleClick = useCallback(() => {
    if (canOutsideClickClose) {
      onCloseRef.current!();
    }
  }, [canOutsideClickClose]);

  useLockBodyScroll({ isLocked: isOpen });

  // Focus management
  useEffect(() => {
    const overlayElement = overlayRef.current;
    const inputElements = overlayElement?.getElementsByTagName('input');

    if (overlayElement && isOpen && inputElements?.length === 0) {
      overlayElement.focus();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffectOnce(() => {
    if (canEscapeKeyClose) {
      const handleKeyDown = (event: KeyboardEvent) => {
        if (event.code === 'Escape') {
          onCloseRef.current!();
        }
      };

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }
  });

  const modal = (
    <div
      tabIndex={-1}
      ref={mergedRef}
      aria-hidden={!isOpen} // Add aria-hidden attribute
      aria-modal={isOpen ? 'true' : undefined} // Add aria-modal attribute
      role="dialog" // Indicate dialog role
      className={styles.overlay}
    >
      <div
        className={clsx({
          [styles.backdrop]: true,
          backdropClassName,
          [styles.background]: hasBackdrop,
        })}
        onClick={handleClick}
        aria-hidden={!canOutsideClickClose} // Hide backdrop from screen readers if not interactive
      />
      {children}
    </div>
  );

  const overlay = shouldUseAnimation ? (
    <FadeTransition inProp={isOpen}>{modal}</FadeTransition>
  ) : (
    modal
  );

  if (!isOpen && !shouldUseAnimation) {
    return null;
  }

  if (shouldUsePortal) {
    return <Portal>{overlay}</Portal>;
  }

  return overlay;
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Overlay.displayName = 'Overlay';
}
