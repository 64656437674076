import type { ElementType, ReactNode, ComponentPropsWithRef, JSX } from 'react';

import clsx from 'clsx';

import { SVGProps } from '@/types/common.types';

import styles from '@/components/List/List.module.scss';
import { ListElement } from '@/components/List/ListElement';

// =================================================================

type ListProps<E extends ElementType> = {
  as?: E;
  icon?: (props: SVGProps) => JSX.Element;
  className?: string;
  children?: ReactNode;
} & ComponentPropsWithRef<E>;

// =================================================================

export const List = <E extends ElementType = 'ul'>({
  as: Element = 'ul',
  icon: Icon,
  className,
  children,
  ...rest
}: ListProps<E>) => {
  return (
    <Element {...rest} className={clsx(styles.listBase, className)}>
      {children}
    </Element>
  );
};

List.Element = ListElement;
