'use client';

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

// =================================================================

export type Option = {
  label: string;
  value: string | number;
};

export type SelectProps = ClassNameProps &
  ChildrenProps & {
    isMulti: boolean;
    options: Option[];
    name: string;
    isDisabled?: boolean;
    placeholder?: string;
    isClearable?: boolean;
    menuIsOpen?: boolean;
    onMenuOpen?: () => void;
    onChange?: (value: Option[] | Option) => void;
    value: Option[] | Option;
    initialValue?: Option[] | Option;
    maxSelectableOptions?: number;
  };

type SelectContextType = Omit<SelectProps, 'children'> & {
  isMaxSelectableLimit?: boolean;
  handleChange: (value: Option['value']) => void;
  isActiveDropdownItem: (optionValue: Option['value']) => boolean;
  selectedOptions: Option[];
  selectedOption: Option | null;
  setSelectedOptions: React.Dispatch<React.SetStateAction<Option[]>>;
  setSelectedOption: React.Dispatch<React.SetStateAction<Option | null>>;
  isMulti: boolean;
  onChange: (value: Option | Option[]) => void;
  value: Option | Option[];
  maxSelectableOptions?: number;
  initialValue?: Option | Option[];
};

// =================================================================

const SelectContext = createContext<SelectContextType | null>(null);

// =================================================================

export const SelectProvider = (props: SelectProps) => {
  const {
    children,
    isMulti,
    options,
    name,
    isDisabled,
    placeholder,
    onChange,
    menuIsOpen = true,
    onMenuOpen,
    value,
    initialValue,
    maxSelectableOptions,
    ...rest
  } = props;

  const [selectedOptions, setSelectedOptions] = useState<Option[]>(
    isMulti && value ? (value as Option[]) : [],
  );

  const [selectedOption, setSelectedOption] = useState<Option | null>(
    !isMulti && value ? (value as Option | null) : null,
  );

  useEffect(() => {
    if (isMulti) {
      setSelectedOptions(value ? (value as Option[]) : []);
    } else {
      setSelectedOption(value ? (value as Option | null) : null);
    }
  }, [value, isMulti]);

  const isMaxSelectableLimit = useMemo(
    () => selectedOptions.length + 1 > Number(maxSelectableOptions),
    [maxSelectableOptions, selectedOptions],
  );

  const handleChange = (value: Option['value']) => {
    if (!isActiveDropdownItem(value) && isMaxSelectableLimit) return;

    const selectedOption = options.find(option => option.value === value);

    if (selectedOption) {
      if (isMulti) {
        let mergedOptions: Option[] = [];

        if (selectedOptions.find(option => option.value === value)) {
          mergedOptions = selectedOptions.filter(option => option.value !== value);
        } else {
          mergedOptions = selectedOptions.concat(selectedOption);
        }

        setSelectedOptions(mergedOptions);
        if (typeof onChange === 'function') onChange(mergedOptions);
      } else {
        setSelectedOption(selectedOption);
        if (typeof onChange === 'function') onChange(selectedOption);
      }
    }
  };

  const isActiveDropdownItem = useCallback(
    (optionValue: Option['value']) => {
      if (isMulti) {
        return selectedOptions.some(selectedOption => selectedOption.value === optionValue);
      } else {
        return optionValue === selectedOption?.value;
      }
    },
    [selectedOptions, selectedOption, isMulti],
  );

  return (
    <SelectContext.Provider
      value={{
        isMulti,
        value,
        maxSelectableOptions,
        options,
        name,
        isDisabled,
        placeholder,
        menuIsOpen,
        isMaxSelectableLimit,
        selectedOptions,
        selectedOption,

        setSelectedOption,
        setSelectedOptions,
        onChange: onChange!,
        onMenuOpen,
        handleChange,
        isActiveDropdownItem,
        ...rest,
      }}
    >
      {children}
    </SelectContext.Provider>
  );
};

// =================================================================

export const useSelectContext = () => {
  const context = useContext(SelectContext);

  if (!context) {
    throw new Error('useSelectContext must be used within a SelectContext');
  }

  return context;
};
