import { type BaseTransitionProps, BaseTransition } from '@/components/Animations/BaseTransition';
import styles from '@/components/Animations/FadeTransition.module.scss';

// =================================================================

export const FadeTransition = (props: Omit<BaseTransitionProps, 'classNames'>) => {
  const { children, ...rest } = props;

  return (
    <BaseTransition
      classNames={{
        enter: styles.fadeEnter,
        enterActive: styles.fadeEnterActive,
        exit: styles.fadeExit,
        exitActive: styles.fadeExitActive,
      }}
      {...rest}
    >
      {children}
    </BaseTransition>
  );
};
