import { type ElementType, type ReactNode, type ComponentPropsWithRef, type JSX } from 'react';

import clsx from 'clsx';

import { SVGProps } from '@/types/common.types';

import styles from '@/components/List/ListElement.module.scss';

// =================================================================

type ListElementProps<E extends ElementType> = {
  as?: E;
  icon?: (props: SVGProps) => JSX.Element;
  className?: string;
  children?: ReactNode;
  activeClassName?: string;
  isActiveElement?: boolean;
  iconWidth?: string | number;
  iconHeight?: string | number;
  iconClassName?: string;
  isLast: boolean;
} & ComponentPropsWithRef<E>;

// =================================================================

export const ListElement = <E extends ElementType>({
  as: Element = 'li',
  icon: Icon,
  className,
  activeClassName = styles.active,
  children,
  isActiveElement,
  iconWidth = 24,
  iconHeight = 24,
  iconClassName,
  isLast,
  ...rest
}: ListElementProps<E>) => {
  return (
    <Element
      className={clsx(
        {
          [styles.listElement]: true,
          [activeClassName]: isActiveElement,
          [styles.lastItem]: isLast,
          [styles.hasIcon]: Icon,
        },
        className,
      )}
      {...rest}
    >
      {Icon && (
        <span className={clsx(styles.iconWrapper, iconClassName)}>
          <Icon className={styles.listIcon} width={iconWidth} height={iconHeight} />
        </span>
      )}

      <div
        className={clsx({
          [styles.contentWrapper]: true,
          [styles.hasIconContent]: Icon,
        })}
      >
        {children}
      </div>
    </Element>
  );
};
