import { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';

// =================================================================

export type CSSTransitionClassNames = {
  appear?: string;
  appearActive?: string;
  appearDone?: string;
  enter?: string;
  enterActive?: string;
  enterDone?: string;
  exit?: string;
  exitActive?: string;
  exitDone?: string;
};

export type BaseTransitionProps = {
  key?: string;
  inProp: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  timeout?: number | { appear?: number; enter?: number; exit?: number };
  classNames?: CSSTransitionClassNames;
  children: ReactNode;
};

// =================================================================

export const BaseTransition = (props: BaseTransitionProps) => {
  const {
    inProp,
    key,
    timeout = 200,
    classNames,
    unmountOnExit = true,
    mountOnEnter = true,
    children,
  } = props;

  return (
    <CSSTransition
      key={key}
      in={inProp}
      mountOnEnter={mountOnEnter}
      unmountOnExit={unmountOnExit}
      timeout={timeout}
      classNames={classNames}
    >
      {children}
    </CSSTransition>
  );
};
