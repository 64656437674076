// =================================================================
import { useEffect, JSX } from 'react';

import clsx from 'clsx';

import ChevronDownSVG from '@/icons/ChevronDownSVG';
import { ChildrenProps, ClassNameProps, SVGProps } from '@/types/common.types';

import { useDropdownContext } from '@/components/Dropdown/contexts/useDropdownContext';
import { useHover } from '@/hooks/useHover';

import styles from '@/components/Dropdown/DropdownLabel.module.scss';

// =================================================================

interface DropdownLabelProps extends Partial<ChildrenProps>, ClassNameProps {
  icon?: (props: SVGProps) => JSX.Element;
  arrowIcon?: (props: SVGProps) => JSX.Element;
  onClick?: () => void;
  iconWidth?: number;
  iconHeight?: number;
  title?: string;
  arrowClassName?: string;
  disabled?: boolean;
}

// =================================================================

export const DropdownLabel = (props: DropdownLabelProps) => {
  const {
    icon: Icon,
    arrowIcon: ArrowIcon = ChevronDownSVG,
    children,
    className,
    onClick,
    iconWidth = 20,
    iconHeight = 20,
    title,
    arrowClassName,
    disabled = false,
  } = props;
  const { toggleDropdown, trigger, isDropdownVisible } = useDropdownContext();

  const { ref, isHovered } = useHover<HTMLButtonElement>();

  useEffect(() => {
    if (disabled) return;
    else if (trigger === 'hover' && isHovered) {
      toggleDropdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, trigger, disabled]);

  return (
    <button
      ref={ref}
      aria-label="Toggle dropdown"
      title={title}
      aria-haspopup="true"
      aria-expanded={isDropdownVisible}
      type="button"
      onClick={disabled || trigger === 'click' ? toggleDropdown : onClick}
      className={clsx(styles.dropdownLabel, className)}
      disabled={disabled}
    >
      {Icon && <Icon width={iconWidth} height={iconHeight} />}
      {children}
      <ArrowIcon
        width={16}
        height={16}
        className={clsx(arrowClassName, styles.chevronDownIcon, {
          [styles.active]: isDropdownVisible,
        })}
      />
    </button>
  );
};
