'use client';

import React, { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import cn from 'classnames';

import PasswordViewOffSVG from '@/icons/PasswordViewOffSVG';
import PasswordViewOnSVG from '@/icons/PasswordViewOnSVG';
import { ClassNameProps, SVGProps } from '@/types/common.types';

import { normalizeSpaces } from '@/helpers/transformations.helpers';

import styles from '@/components/Form/FormInput.module.scss';

// =================================================================

type FormInputProps = ClassNameProps & {
  name: string;
  type?: string;
  label?: string;
  placeholder?: string;
  icon?: React.ComponentType<SVGProps>;
  rightIcon?: React.ComponentType<SVGProps>;
  passwordView?: boolean;
  iconWidth?: number;
  iconHeight?: number;
  normalizeOnBlur?: boolean;
  maxLength?: number;
  errorClassName?: string;
  autoFocus?: boolean;
  onFocus?: () => void;
  onClick?: () => void;
  size?: 'sm' | 'md' | 'lg';
};

// =================================================================

export const FormInput = (props: FormInputProps) => {
  const {
    name,
    type = 'text',
    label,
    placeholder,
    icon: Icon,
    rightIcon: RightIcon,
    passwordView = false,
    iconWidth = 24,
    iconHeight = 24,
    normalizeOnBlur = false,
    maxLength = 36,
    errorClassName,
    className,
    autoFocus = false,
    onFocus,
    size,
    onClick,
  } = props;

  const { control } = useFormContext();

  const {
    field: { onBlur, ...fieldProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });
  const isInputEmpty = fieldProps.value === '';

  const [isFocused, setIsFocused] = useState(false);
  const [inputType, setInputType] = useState(type);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    if (normalizeOnBlur) {
      fieldProps.onChange(normalizeSpaces(e.target.value));
    }
    onBlur();
  };

  return (
    <label className={styles.formGroup}>
      {label && <span className={styles.formLabel}>{label}</span>}
      <div
        className={cn(className, {
          [styles.formField]: true,
          [styles.focused]: isFocused,
          [styles.hasError]: error,
        })}
        onClick={onClick}
      >
        {Icon && (
          <label
            htmlFor={name}
            className={cn({
              [styles.label]: true,
              [styles.notEmpty]: !isInputEmpty,
              [styles.hasError]: error,
              [styles.left]: true,
            })}
          >
            <Icon width={iconWidth} height={iconHeight} className={styles.labelIcon} />
          </label>
        )}

        <input
          autoFocus={autoFocus}
          type={inputType}
          id={name}
          className={cn(size && styles[size], {
            [styles.formInput]: true,
            [styles.hasError]: error,
            [styles.hasIcon]: Icon,
            [styles.passwordInput]: inputType === 'password' && !isInputEmpty,
          })}
          onFocus={() => {
            if (typeof onFocus === 'function') onFocus();
            setIsFocused(true);
          }}
          onBlur={handleBlur}
          maxLength={maxLength}
          placeholder={placeholder}
          {...fieldProps}
        />
        {passwordView && (
          <label
            htmlFor={name}
            className={cn({
              [styles.passwordViewLabel]: true,
              [styles.right]: true,
              [styles.notEmpty]: !isInputEmpty,
              [styles.hasError]: error,
            })}
          >
            {inputType === 'text' ? (
              <PasswordViewOnSVG
                width={iconWidth}
                height={iconHeight}
                className={styles.passwordIcon}
                onClick={() => setInputType('password')}
              />
            ) : (
              <PasswordViewOffSVG
                width={iconWidth}
                height={iconHeight}
                className={styles.passwordIcon}
                onClick={() => setInputType('text')}
              />
            )}
          </label>
        )}

        {RightIcon && (
          <label
            htmlFor={name}
            className={cn({
              [styles.phoneLabel]: true,
              [styles.right]: true,
              [styles.hasError]: error,
            })}
          >
            <RightIcon width={iconWidth} height={iconHeight} className={styles.labelIcon} />
          </label>
        )}
      </div>

      {error && <div className={cn(styles.error, errorClassName)}>{error.message}</div>}
    </label>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  FormInput.displayName = 'FormInput';
}
