'use client';

import { useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useEffectOnce } from 'react-use';

import type { ChildrenProps } from '@/types/common.types';

export const Portal = (props: ChildrenProps) => {
  const { children } = props;

  const portalRef = useRef<HTMLElement | null>(null);

  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffectOnce(() => {
    portalRef.current = document.getElementById('portal') as HTMLElement;
    setIsMounted(true);
  });

  if (!isMounted) {
    return null;
  }

  return createPortal(children, portalRef.current!);
};
