import React, { Fragment } from 'react';

import { isEmpty } from 'lodash';

import ArrowSVG from '@/icons/ArrowSVG';

import { Dropdown } from '@/components/Dropdown/Dropdown';
import { FormCheckbox } from '@/components/Form/FormCheckbox';
import styles from '@/components/Form/Select/SelectDropdown.module.scss';
import {
  MultiValue,
  MultiValueContainer,
  SingleValue,
} from '@/components/Form/Select/SelectValues';
import { useSelectContext } from '@/components/Form/Select/context/SelectProvider';

// =================================================================

export const SelectDropdown = () => {
  const {
    isMulti,
    selectedOptions,
    selectedOption,
    handleChange,
    onMenuOpen,
    menuIsOpen,
    name,
    isDisabled,
    placeholder,
    options,
    isActiveDropdownItem,
    isMaxSelectableLimit,
  } = useSelectContext();

  return (
    <div className={styles.selectDropdownWrapper}>
      <Dropdown
        trigger="click"
        value={
          isMulti
            ? (selectedOptions?.map(selected => selected.value) as number[] | string[])
            : selectedOption?.value
        }
        onChange={value => handleChange(value)}
        onMenuOpen={onMenuOpen}
      >
        <Dropdown.Label
          title={name}
          className={styles.selectLabel}
          arrowClassName={styles.arrowClassName}
          arrowIcon={ArrowSVG}
          disabled={isDisabled}
        >
          <Fragment>
            {isMulti ? (
              <MultiValueContainer>
                {selectedOptions.map(selected => (
                  <MultiValue key={selected.value}>{selected.label}</MultiValue>
                ))}
              </MultiValueContainer>
            ) : (
              <SingleValue>{selectedOption?.label}</SingleValue>
            )}

            {isEmpty(selectedOptions) && isEmpty(selectedOption) && (
              <SingleValue isPlaceholder>{placeholder}</SingleValue>
            )}
          </Fragment>
        </Dropdown.Label>

        {menuIsOpen && (
          <Dropdown.Menu dropdownClassName={styles.dropdownMenu}>
            {options.map((option, index) => (
              <li key={option.value}>
                <Dropdown.Option
                  as="button"
                  type="button"
                  value={option.value}
                  activeClassName={styles.activeDropdownItem}
                  isLast={index === options.length - 1}
                >
                  <FormCheckbox
                    onChange={() => handleChange(option.value)}
                    label={option.label}
                    name={''}
                    shape="round"
                    className={styles.dropdownItem}
                    checked={isActiveDropdownItem(option.value)}
                    disabled={!isActiveDropdownItem(option.value) && isMaxSelectableLimit}
                  />
                </Dropdown.Option>
              </li>
            ))}
          </Dropdown.Menu>
        )}
      </Dropdown>
    </div>
  );
};
