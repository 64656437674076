import { memo } from 'react';

import { Button } from '../Buttons';
import clsx from 'clsx';

import CloseSVG from '@/icons/CloseSVG';
import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import styles from './Modal.module.scss';

// =================================================================

interface ModalHeaderProps extends Partial<ChildrenProps>, ClassNameProps {
  onClose?: () => void;
  isCloseButtonShown?: boolean;
}

// =================================================================

export const ModalHeader = memo((props: ModalHeaderProps) => {
  const { onClose, children, className, isCloseButtonShown = true } = props;

  return (
    <div
      className={clsx(
        {
          [styles.modalHeader]: true,
        },
        className,
      )}
    >
      {children}

      {isCloseButtonShown && (
        <Button
          as="button"
          size="md"
          variant="secondary"
          shape="circle"
          title={'Закрыть'}
          className={styles.modalCloseButton}
          onClick={onClose}
        >
          <CloseSVG className={styles.closeButtonIcon} width={16} height={16} />
        </Button>
      )}
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  ModalHeader.displayName = 'ModalHeader';
}
