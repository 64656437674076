'use client';

import React from 'react';
import { useController, useFormContext } from 'react-hook-form';

import clsx from 'clsx';

import CheckmarkSVG from '@/icons/CheckmarkSVG';

import styles from '@/components/Form/FormCheckbox.module.scss';

// =================================================================

type FormCheckboxProps = Omit<React.HtmlHTMLAttributes<HTMLInputElement>, 'onClick'> & {
  label: React.JSX.Element | string;
  name: string;
  disabled?: boolean;
  shape?: 'circle' | 'round';
  checked?: boolean;
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
};

// =================================================================

export const FormCheckbox = (props: FormCheckboxProps) => {
  const {
    name,
    label,
    disabled,
    shape = 'round',
    checked,
    onChange: propsOnChange,
    className,
    ...rest
  } = props;

  const { control } = useFormContext();

  const {
    field: { value, onChange, ...fieldProps },
  } = useController({
    name,
    control,
    defaultValue: checked,
  });

  return (
    <div className={clsx(styles.formCheckbox, className)}>
      <input
        id={name}
        type="checkbox"
        value={value}
        checked={!!value}
        className={clsx(styles.checkbox, {
          [styles.disabled]: disabled,
        })}
        disabled={disabled}
        onChange={event => {
          onChange(event);
          if (typeof propsOnChange === 'function') {
            propsOnChange(event);
          }
        }}
  
        {...fieldProps}
        {...rest}
      />

      <label
        htmlFor={name}
        className={clsx(styles.label, {
          [styles.disabled]: disabled,
        })}
      >
        <span
          className={clsx(styles.checkedIconContainer, styles[shape], {
            [styles.checked]: value,
          })}
        >
          {value && <CheckmarkSVG className={styles.checkedIcon} />}
        </span>

        {label}
      </label>
    </div>
  );
};

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  FormCheckbox.displayName = 'FormCheckbox';
}
