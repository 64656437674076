'use client';

import clsx from 'clsx';

import { ModalOptionsSelect } from '@/components/Form/Select/ModalOptionsSelect';
import styles from '@/components/Form/Select/Select.module.scss';
import { SelectDropdown } from '@/components/Form/Select/SelectDropdown';
import { SelectProps, SelectProvider } from '@/components/Form/Select/context/SelectProvider';

// =================================================================

export const Select = (props: SelectProps) => {
  const { className, children, ...rest } = props;

  return (
    <div className={clsx(styles.select, className)}>
      <SelectProvider {...rest}>{children}</SelectProvider>
    </div>
  );
};

// =================================================================

Select.Dropdown = SelectDropdown;
Select.ModalDropdown = ModalOptionsSelect;
