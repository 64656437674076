import { BaseTransition, type BaseTransitionProps } from '@/components/Animations/BaseTransition';
import styles from '@/components/Animations/SlideTransition.module.scss';

// =================================================================

export const SlideTransition = (props: Omit<BaseTransitionProps, 'classNames' | 'timeout'>) => {
  const { children, ...rest } = props;

  return (
    <BaseTransition
      timeout={500}
      classNames={{
        enter: styles.slideEnter,
        enterActive: styles.slideEnterActive,
        exit: styles.slideExit,
        exitActive: styles.slideExitActive,
      }}
      {...rest}
    >
      {children}
    </BaseTransition>
  );
};
