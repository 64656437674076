'use client';

import { forwardRef, useRef } from 'react';
import { useEffectOnce } from 'react-use';

import { ChildrenProps, ClassNameProps } from '@/types/common.types';

import { useMergeRefs } from '@/hooks/useMergeRefs';

// =================================================================

interface FocusableProps extends ChildrenProps, Partial<ClassNameProps> {
  shouldFocusOnMount?: boolean;
  as?: 'div' | 'aside';
}

// =================================================================

export const Focusable = forwardRef<HTMLDivElement, FocusableProps>((props, ref) => {
  const { children, className, shouldFocusOnMount, as = 'div' } = props;

  const nodeRef = useRef<HTMLDivElement | null>(null);

  const mergedRefs = useMergeRefs(ref, nodeRef);

  useEffectOnce(() => {
    if (shouldFocusOnMount && nodeRef.current) {
      nodeRef.current.setAttribute('tabindex', '0');
      nodeRef.current.focus();
    }
  });

  if (as === 'aside') {
    return (
      <aside ref={mergedRefs} className={className}>
        {children}
      </aside>
    );
  }

  return (
    <div ref={mergedRefs} className={className}>
      {children}
    </div>
  );
});

// =================================================================

if (process.env.NODE_ENV !== 'production') {
  Focusable.displayName = 'Focusable';
}
